import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vant/lib/index.css";
import "lib-flexible";
import "./styles/index.scss";
Vue.config.productionTip = false;

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('happy_landing_page_token');
	if (token === '' && (to.path !== '/login' && to.path !== '/jump' && to.path !== '/jf-down')) {
		next('/login');
	} else {
		next();
	}
});


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
