// 登录相关
const loginRoutes = [
	{
		path: "/",
		redirect: '/login'
	},
	{
		path: "/login",
		name: "嗨皮直播-注册",
		component: () => import("views/login/index.vue")
	},
	{
		path: "/jump",
		name: "嗨皮直播-loading",
		component: () => import("views/jump/index.vue")
	},
	{
		path: "/dynamic",
		name: "嗨皮直播-动态",
		component: () => import("views/dynamic/index.vue")
	},
	{
		path: "/jf-down",
		name: "嗨皮直播-下载",
		component: () => import("views/other/jf.vue")
	},
];


export default [...loginRoutes];
